.emoji {
  font-size:72px;
}

form {
  padding:10px;
  display:grid;
  min-width: 300px;
  max-width: 400px;
  max-height:300px;
  border-radius:2px;
  background-color:white;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
   
}

form:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

input {
  margin: 5px;
  padding: 10px;
  font-size:18px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button{
  color:white;
  margin: 5px;
  font-size:18px;
  border-radius: 4px;
  border:none;
  font-weight: 600;
  letter-spacing: 1.2px;
  cursor:pointer;
  outline: none;
  background-color:dodgerblue;
}

button:hover {
    background-color:royalblue;
}