@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}







.emoji {
  font-size:72px;
}

form {
  padding:10px;
  display:grid;
  min-width: 300px;
  max-width: 400px;
  max-height:300px;
  border-radius:2px;
  background-color:white;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
   
}

form:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

input {
  margin: 5px;
  padding: 10px;
  font-size:18px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button{
  color:white;
  margin: 5px;
  font-size:18px;
  border-radius: 4px;
  border:none;
  font-weight: 600;
  letter-spacing: 1.2px;
  cursor:pointer;
  outline: none;
  background-color:dodgerblue;
}

button:hover {
    background-color:royalblue;
}
.page {
    padding:0px 0 100px 0;
    min-height:100vh; 
    width:100vw;
    background-color:#f1f3f5;
}
.not_found {
    display:grid;
    text-align:center;
    -webkit-align-items: center;
            align-items: center;
    grid-template-rows:1fr;
    grid-template-columns:1fr;
}
.login_view {
    display:grid;
    -webkit-align-items:center;
            align-items:center;
    justify-items:center;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
}
.page-view {
    display:grid;
    overflow-y:auto;
    margin-top:50px;
    text-align:center;
    -webkit-align-items: center;
            align-items: center;
    grid-template-rows:1fr;
    grid-template-columns:1fr;
}
